<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <div>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="form.username"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="绑定手机" prop="phone">
          <el-input
            v-model="form.phone"
            placeholder="请输入绑定手机"
          ></el-input>
        </el-form-item>
        <el-form-item label="登录邮箱" prop="email">
          <el-input
            v-model="form.email"
            placeholder="请输入登录邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱密码" prop="email_password">
          <el-input
            v-model="form.email_password"
            placeholder="请输入邮箱密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="秘钥" prop="two_step_key">
          <el-input
            v-model="form.two_step_key"
            placeholder="请输入两步验证秘钥"
          ></el-input>
        </el-form-item>
        <el-form-item label="备用码" prop="backup_code">
          <el-input
            v-model="form.backup_code"
            placeholder="请输入备用码"
          ></el-input>
        </el-form-item>
        <el-form-item label="cookie" prop="cookie">
          <el-input v-model="form.cookie" placeholder="cookie"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" placeholder="备注"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitFun">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { submit_add_api } from '@/api/twitter'
export default {
  data() {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    initFun() {
      // this.$refs['form'].resetFields()
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      this.form = {}
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    submitFun() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          submit_add_api(this.form).then(res => {
            this.$message.success('提交成功！')
            this.dialogVisible = false
            this.$emit('updateFun')
          })
        }
      })
    }
  }
}
</script>
