<template>
  <div class="app-container">
    <el-form
      :inline="true"
      :model="queryForm"
      class="demo-form-inline account-top"
    >
      <el-form-item label="搜索">
        <el-input
          v-model="queryForm.search"
          placeholder="用户名备注等"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-plus" @click="addFun">
          提交
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      class="tableList1"
    >
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column prop="account" label="twitter账号">
        <template slot-scope="scope">
          <el-link type="primary" @click="toPage(scope.row)">
            {{ scope.row.account }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="password" label="密码"></el-table-column>
      <el-table-column prop="phone" label="绑定手机"></el-table-column>
      <el-table-column prop="email" label="登录邮箱"></el-table-column>
      <el-table-column prop="email_password" label="邮箱密码"></el-table-column>
      <el-table-column
        prop="two_step_key"
        label="两步验证秘钥"
      ></el-table-column>
      <el-table-column prop="backup_code" label="备用码"></el-table-column>
      <el-table-column prop="status" label="状态" width="100">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.status == 1"
            type="success"
            disable-transitions
          >
            处理成功
          </el-tag>
          <el-tag
            v-else-if="scope.row.status == 0"
            type="warning"
            disable-transitions
          >
            待处理
          </el-tag>
          <el-tag
            v-else-if="scope.row.status == -1"
            type="danger"
            disable-transitions
          >
            处理失败
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="cookie" prop="cookie"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column prop="create_time" label="创建时间"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- <el-button
            size="mini"
            type="primary"
            @click="handlePostTask(scope.$index, scope.row)"
          >
            详情
          </el-button> -->

          <el-button size="mini" type="danger" @click="delFun(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="queryForm.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
    ></el-pagination>
    <addDialog ref="addDialogRef" @updateFun="updateFun" />
  </div>
</template>

<script>
import { submit_api, submit_del_api } from '@/api/twitter'
import addDialog from './components/submissionDialog.vue'
export default {
  components: {
    addDialog
  },
  data() {
    return {
      accountId: 0,
      list: null,
      listLoading: true,
      queryForm: {
        pageIndex: 1,
        pageSize: 10,

        search: ''
      },
      count: 0,

      userList: [],
      current: 1,
      // 每页记录数
      limit: 5,
      // 总记录数
      total: 0
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {},
  methods: {
    async fetchData() {
      this.listLoading = true
      const { all_count: count, items } = await submit_api(this.queryForm)
      this.count = count
      this.list = items

      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.pageIndex = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.pageSize = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.pageIndex = pageIndex
      // 分页变更时处理
      this.fetchData()
    },

    // 提交账号
    addFun() {
      this.$refs.addDialogRef.initFun()
    },
    //
    updateFun() {
      this.fetchData()
    },
    delFun(row) {
      console.log(row)
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          submit_del_api(row.id).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.list = []
            this.queryForm.pageIndex = 1
            this.fetchData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    toPage(row) {
      this.$router.push({
        path: '/twitter/account',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.account-top {
  white-space: nowrap;
}
::v-deep .el-table__body-wrapper {
  height: calc(100vh - 310px) !important;
}
</style>
